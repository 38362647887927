.wblock {
  width: 100%;
  margin-bottom: 40px;
}

.wblock .header {
  color: #3f576e;
  margin: 10px 0px 10px 0px;
  font-size: 24px;
  font-family: "Helvetica";
  font-weight: bold;
}

.wblock .text {
  font-size: 16px;
  font-family: "Helvetica";
  color: #637280;
  line-height: 22px;
  font-weight: normal;
}
