.app {
  width: 100%;
  height: 100%;
}

.contentWrapper {
  min-height: calc(100vh - 29vw);
  padding-top: 20vw;
}

.web {
  height: 100%;
  min-height: calc(100vh - 60px);
  justify-content: center;
  display: flex;
  width: 100%;
  flex-direction: row;
  box-sizing: border-box;
}

.callButtonContainer {
  position: fixed;
  z-index: 99;
  bottom: 16vw;
  right: 6vw;
}

.callButton {
  background-color: rgba(0, 150, 255, 0.5);
  width: 16vw;
  height: 16vw;
  border-radius: 8vw;
  align-items: center;
  justify-content: center;
  display: flex;
}

.callBack {
  background-color: rgba(0, 150, 255, 0.5);
  width: 16vw;
  height: 16vw;
  border-radius: 8vw;
  position: absolute;
  z-index: 1;
}

.call {
  z-index: 2;
}

a:link {
  color: #637280;
}

a:visited {
  color: #637280;
}

a:hover {
  color: #0088ee;
}

a:active {
  color: #637280;
}
