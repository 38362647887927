table,
td,
th,
tr {
  border-style: solid;
  border-width: 1px;
  border-collapse: collapse;
  border-color: #bbbbbb;
}

table {
  width: 100%;
}

table .head {
  background-color: #dfdfdf;
}

table .left {
  width: 10%;
  text-align: center;
}

table .leftW {
  width: 5%;
  text-align: center;
}

table .right {
  width: 20%;
  text-align: center;
}

table .rightW {
  width: 25%;
  text-align: center;
}

table .centerAdd {
  padding-left: 3vw;
}

table .centerAddW {
  padding-left: 10px;
}

table .center {
  width: 70%;
  padding-left: 3vw;
  padding-right: 1vw;
}

table .centerW {
  width: 70%;
  padding-left: 10px;
  padding-right: 5px;
}

table h1 {
  font-size: 4vw;
  color: #6f6666;
  font-family: "Helvetica";
}

table h1.x {
  font-size: 18px;
  color: #6f6666;
  line-height: 24px;
  font-family: "Helvetica";
}

table h2 {
  font-size: 4vw;
  font-family: "Helvetica";
  color: #6f6666;
  line-height: 5vw;
  font-weight: normal;
}

table h2.x {
  font-size: 16px;
  font-family: "Helvetica";
  color: #6f6666;
  line-height: 20px;
  font-weight: normal;
}

table .rowt {
  background-color: #eee;
}

p.text {
  font-size: 4vw;
  font-family: "Helvetica";
  color: #637280;
  line-height: 5vw;
  font-weight: normal;
}

p.textW {
  font-size: 16px;
  font-family: "Helvetica";
  color: #637280;
  line-height: 22px;
  font-weight: normal;
}
