.block {
  width: 100%;
  margin-bottom: 8vw;
}

.block .header {
  color: #3f576e;
  margin: 5vw 0px 2vw 0px;
  font-size: 5.5vw;
  font-family: "Helvetica";
  font-weight: bold;
}

.block .text {
  font-size: 4vw;
  font-family: "Helvetica";
  color: #637280;
  line-height: 5vw;
  font-weight: normal;
}
