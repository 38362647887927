.footer {
  height: 12vw;
  width: 100%;
  background-color: #eee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer h1 {
  font-weight: normal;
  font-size: 2vw;
  opacity: 0.3;
}

.footer .line {
  width: 100%;
  height: 1px;
  background-color: #ddd;
}

.footer .text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
