.mobileHeader {
  height: 20vw;
  width: 100%;
  background-color: #004477;
  position: fixed;
  z-index: 999;
  box-sizing: border-box;
}

.mobileHeader .container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 3.5vw 0 5vw;
  padding-top: 3.8vw;
  width: 100%;
  box-sizing: border-box;
}

button:focus {
  outline: 0;
}

.mobileHeader .menuMainButton {
  background-color: transparent;
  border: 0;
  z-index: 888;
}

.mobileHeader .logoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mobileHeader .logo {
  width: 8vw;
  height: 8vw;
  margin-right: 2vw;
}

.mobileHeader .logoOver {
  width: 8vw;
  height: 8vw;
  margin-right: 2vw;
  position: absolute;
  opacity: 0.5;
}

.mobileHeader .logoName {
  display: inline;
}

.mobileHeader h1 {
  color: white;
  font-size: 5.5vw;
  letter-spacing: 0.1vw;
  line-height: 1vw;
}

.mobileHeader h2 {
  color: white;
  font-size: 2.37vw;
  opacity: 0.5;
}

.mobileHeader .buttonsContainer {
  flex-direction: column;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.mobileHeader .menuButton {
  flex-direction: row;
  display: flex;
  background-color: transparent;
  border: 0;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 15vw;
  background-color: rgba(255, 255, 255, 0.05);
  margin-bottom: 1px;
  box-sizing: border-box;
  padding: 0 2vw 0 5vw;
}

.mobileHeader .bottomCopy {
  position: absolute;
  bottom: 2vh;
  align-self: center;
  text-align: center;
  width: 100%;
}
