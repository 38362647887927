.contentWeb {
  width: 1100px;
  min-width: 500px;
  padding: 120px 32px 0px 32px;
  display: inline-block;
  box-sizing: border-box;
}

.hyperlinkColor {
  color: #0088ee;
}

.contentWeb .blockWithPhoto {
  flex-direction: row;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.contentWeb .blockWithPhotoLeft {
  width: 45%;
}

.contentWeb .blockWithPhotoRight {
  width: 55%;
}

.contentWeb .blockWithPhotoImage {
  width: 100%;
  height: auto;
}

.contentWeb .logosContainer {
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100px;
}

.contentWeb .logoH {
  transition: filter 0.5s, -webkit-filter 0.5s, opacity 0.5s, transform 0.5s;
  width: 20%;
  -webkit-filter: grayscale(100%) contrast(400%);
  filter: grayscale(100%) contrast(400%);
  opacity: 0.5;
  box-sizing: border-box;
}

.contentWeb .logoH:hover {
  -webkit-filter: grayscale(0%) contrast(100%);
  filter: grayscale(0%) contrast(100%);
  opacity: 1;
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.circle-mapW {
  width: 360px;
  height: 360px;
  overflow: hidden;
  position: relative;
  border: 6px solid #ddd;
  box-sizing: border-box;
  border-radius: 180px;
  filter: grayscale(100%);
  transition: width 0.2s, height 0.2s, border 0.2s, border-radius 0.2s,
    filter 0.2s, border 0.2s;
}

.circle-mapW:hover {
  width: 45%;
  -moz-border-radius: 0%;
  -webkit-border-radius: 0%;
  border-radius: 0%;
  overflow: hidden;
  position: relative;
  border: 2px solid #c2e2ff;
  filter: grayscale(0%);
}

.contentWeb select,
.contentWeb input,
.contentWeb textarea {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #637280;
  stroke: none;
  outline: none;
  padding: 5px;
  margin: 0;
  background-color: #fff;
  box-sizing: content-box;
}

input:focus,
textarea:focus,
select:focus {
  background-color: #f2f9ff;
}

.contentWeb formStyle {
  padding-top: 20px;
  width: 100%;
}

.contentWeb select {
  height: 30px;
  width: 60%;
  appearance: none;
}

.contentWeb input {
  height: 30px;
  width: 60%;
}

.contentWeb textarea {
  resize: none;
  width: 100%;
  box-sizing: border-box;
  height: 120px;
}

.contentWeb textarea::placeholder {
  color: #aaa;
}

.contentWeb .inputbtn {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.5px;
  border: 0px;
  background-color: #004477;
  color: #fff;
  width: 140px;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  stroke: none;
  font-family: "Helvetica";
  align-self: flex-end;
}

.contentWeb .buttonSendContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 20px;
}

.contentWeb .formElement {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  box-sizing: border-box;
}
