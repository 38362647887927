.content {
  padding: 0 5vw 0 5vw;
  width: 100%;
  box-sizing: border-box;
}

.content .logoH {
  width: 23vw;
  -webkit-filter: grayscale(100%) contrast(400%);
  filter: grayscale(100%) contrast(400%);
  opacity: 0.5;
  margin: 3vw;
}

.content .logosContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.circle-map {
  width: 100%;
  overflow: hidden;
  position: relative;
  border: 2vw solid #ddd;
  box-sizing: border-box;
  margin-bottom: 5vw;
}

select,
input,
textarea {
  font-size: 4vw;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 1.5vw;
  border: 1px solid #b2c8db;
  color: #637280;
  stroke: none;
  outline: none;
  padding: 2vw;
  margin: 0;
  background-color: #fff;
  box-sizing: content-box;
  appearance: none;
}

input:focus,
textarea:focus,
select:focus {
  background-color: #f2f9ff;
}

select {
  height: 5vw;
  width: 60vw;
  appearance: none;
}

input {
  height: 5vw;
  width: 60vw;
}

textarea {
  resize: none;
  width: 100%;
  box-sizing: border-box;
  height: 20vw;
}

textarea::placeholder {
  color: #aaa;
}

.inputbtn {
  font-size: 4vw;
  font-weight: bold;
  letter-spacing: 0.1vw;
  border: 0px;
  background-color: #004477;
  color: #fff;
  width: 40vw;
  height: 10vw;
  border-radius: 1.5vw;
  cursor: pointer;
  outline: none;
  stroke: none;
  font-family: "Helvetica";
  align-self: flex-start;
}

.buttonSendContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 5vw;
}

.formElement {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3vw;
  box-sizing: border-box;
}

.imageMobile {
  width: 100%;
  height: auto;
  margin-top: 5vw;
}
