.webHeader {
  height: 80px;
  width: 100%;
  background-color: #004477;
  position: fixed;
  z-index: 999;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.webHeader .container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px 0 32px;
  height: 100%;
  width: 1100px;
  box-sizing: border-box;
  max-width: 1100px;
  min-width: 500px;
}

button:focus {
  outline: 0;
}

.webHeader .logoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.webHeader .logo {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.webHeader .logoOver {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  position: absolute;
  opacity: 0.5;
}

.webHeader .logoName {
  display: inline;
}

.webHeader h1 {
  color: white;
  font-size: 24px;
  letter-spacing: 0.5px;
  line-height: 11px;
  font-family: "Helvetica";
}

.webHeader h2 {
  color: white;
  font-size: 16px;
  opacity: 0.5;
  letter-spacing: 0.5px;
  line-height: 11px;
  font-weight: 500;
  font-family: "Helvetica";
}

.webHeader .buttonsContainer {
  flex-direction: row;
  display: flex;
  width: 48%;
  justify-content: flex-end;
  align-items: center;
}

.webHeader .menuButton {
  height: 80px;
  width: 130px;
  color: #fff;
  font-family: "Helvetica";
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
  stroke: none;
  outline: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  letter-spacing: 0.5px;
  z-index: 10;
}
