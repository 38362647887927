.footerWeb {
  height: 60px;
  width: 100%;
  background-color: #eee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footerWeb h1 {
  font-weight: normal;
  font-size: 14px;
  opacity: 0.3;
}

.footerWeb .line {
  width: 100%;
  height: 1px;
  background-color: #ddd;
}

.footerWeb .text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
